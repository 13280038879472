<template>
  <div class="shopCheckoutAddressPage">
    <div class="checkoutAddressWrapper">
      <div class="sectionAddressForm">
        <h3>{{ $t('shop.order.clientRemark') }}</h3>
        <p-divider class="divider"></p-divider>
        <p-textarea v-model="clientRemark" :aria-label="$t('shop.order.clientRemark')"></p-textarea>
        <il-shop-form-address
          ref="addressForm"
          invoice-disabled
          @valid="onValid"
        ></il-shop-form-address>
      </div>
      <div class="shoppingBasketCosts">
        <il-shop-shopping-basket-costs
          to="checkout-overview"
          :disabled="!isFormValid"
          :show-different-prices="false"
          :can-choose-membership="false"
        ></il-shop-shopping-basket-costs>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import Vue from 'vue';
  import IlShopFormAddress from '@/src/components/shop/il-shop-form-address.vue';
  import IlShopShoppingBasketCosts from '@/src/components/shop/il-shop-shopping-basket-costs.vue';
  import shoppingBasket from '../../../../utilities/shoppingBasket';

  export default Vue.extend({
    components: {
      IlShopFormAddress,
      IlShopShoppingBasketCosts,
    },
    layout: 'layout-widget-shop-reduced',
    data: () => ({
      isFormValid: false,
      articles: [],
      clientRemark: '',
    }),
    watch: {
      clientRemark: {
        handler(newValue) {
          shoppingBasket.setClientRemark(newValue);
        },
      },
    },
    mounted() {
      this.$routerUtils.updateQueryParams({ page: 'checkout-address' }, { preserve: true });
      this.clientRemark = shoppingBasket.getClientRemark()!;
    },
    methods: {
      onValid(isFormValid: boolean) {
        this.$set(this, 'isFormValid', isFormValid);
      },
    },
  });
</script>
