import { render, staticRenderFns } from "./article-list.vue?vue&type=template&id=3833cc7f"
import script from "./article-list.vue?vue&type=script&lang=ts"
export * from "./article-list.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports