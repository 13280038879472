<template>
  <div class="ilShopShoppingBasketCosts" :class="{ isMember: isMember === true, hasChosenMembership: _.isSet(isMember) }">
    <div class="title">
      <h2>{{ $t('shop.shoppingBasket.shoppingBasket') }}</h2>
      <p-icon spacious>
        shopping_basket
      </p-icon>
    </div>
    <p-divider class="divider"></p-divider>
    <div v-if="canChooseMembershipComputed" class="chooseMembershipWrapper">
      <span class="chooseMembershipLabel subtitle">{{ $t('shop.shoppingBasket.areYouAMember') }}</span>
      <div class="chooseMembership">
        <p-radio-field v-model="isMember" class="membershipOption" :label="$t('core.app.yes')" :true-value="true"></p-radio-field>
        <p-radio-field v-model="isMember" class="membershipOption" :label="$t('core.app.no')" :true-value="false"></p-radio-field>
      </div>
      <p-fade-transition class="membershipInfo">
        <a v-if="isMember === false" target="_blank" :href="$config.values['shop-memberinfourl']">
          {{ $t('shop.shoppingBasket.membershipInformation') }}
        </a>
      </p-fade-transition>
      <p-divider class="divider"></p-divider>
    </div>
    <div class="costsWrapper">
      <div class="costsSubtotalWrapper">
        <span class="costsSubtotalLabel subtitle">{{ $t('shop.shoppingBasket.subtotal') }}</span>
        <div v-if="showStandard" class="standardSubtotal">
          <span v-if="showDifferentPricesComputed">{{ $t('shop.price.standard') }}:</span>
          <span>{{ $format.currency(standardSummary.netAmount, 'CHF') }}</span>
        </div>
        <div v-if="showMember" class="memberSubtotal">
          <span v-if="showDifferentPricesComputed">{{ $t('shop.price.member') }}:</span>
          <span>{{ $format.currency(memberSummary.netAmount, 'CHF') }}</span>
        </div>
      </div>
      <p-divider class="divider"></p-divider>
      <div class="costsShippingWrapper">
        <span class="costsShippingLabel subtitle">{{ $t('shop.shoppingBasket.shippingCost') }}</span>
        <span v-if="isShippingAmountCalculated" class="shippingCost">
          {{ $format.currency(shippingInfo.shippingAmount, 'CHF') }}
        </span>
        <span v-else class="shippingCost tooHeavy">
          {{ $t('shop.order.tooHeavy') }}
        </span>
        <span class="shippingCostAbroad caption">{{ $t('shop.shoppingBasket.remarkForDeliveriesAbroad') }}</span>
      </div>
      <div class="costsVatWrapper">
        <span class="costsVatLabel subtitle">{{ $t('shop.shoppingBasket.vat') }}</span>
        <div v-if="showStandard" class="standardVatCosts">
          <span v-if="showDifferentPricesComputed" class="costsVatMemberType">{{ $t('shop.price.standard') }}:</span>
          <span class="costsVat">{{ $format.currency(standardSummary.vatAmount, 'CHF') }}</span>
          <span v-if="showVatDetailsComputed" class="costsVatSummary">
            <span v-for="item in standardSummary.vatSummaryItems" :key="item.vatRate" class="costsVatSummaryEntry">
              {{ $t('shop.shoppingBasket.vatSummary', { vatRate: item.vatRate, netAmount: $format.currency(item.netAmount, 'CHF'), vatAmount: $format.currency(item.vatAmount, 'CHF') }) }}
            </span>
          </span>
        </div>
        <div v-if="showMember" class="memberVatCosts">
          <span v-if="showDifferentPricesComputed" class="costsVatMemberType">{{ $t('shop.price.member') }}:</span>
          <span class="costsVat">{{ $format.currency(memberSummary.vatAmount, 'CHF') }}</span>
          <span v-if="showVatDetailsComputed" class="costsVatSummary">
            <span v-for="item in memberSummary.vatSummaryItems" :key="item.vatRate" class="costsVatSummaryEntry">
              {{ $t('shop.shoppingBasket.vatSummary', { vatRate: item.vatRate, netAmount: $format.currency(item.netAmount, 'CHF'), vatAmount: $format.currency(item.vatAmount, 'CHF') }) }}
            </span>
          </span>
        </div>
      </div>
      <p-divider class="divider"></p-divider>
      <div class="costsTotal">
        <strong class="costsTotalLabel subtitle">{{ $t('shop.shoppingBasket.total') }}</strong>
        <!--MEMBER-->
        <span v-if="hasOrWantsMembership === true && savingsMember > 0" class="costsSavings">
          {{ $t('shop.shoppingBasket.youSave') }}
          <strong>{{ $format.currency(savingsMember, 'CHF') }}</strong>
        </span>
        <div
          v-if="showMember"
          class="memberTotalCosts"
          :class="{ notApplicable: hasOrWantsMembership === false }"
        >
          <span v-if="showDifferentPricesComputed">{{ $t('shop.price.member') }}:</span>
          <span>{{ $format.currency(memberSummary.grandTotalAmount, 'CHF') }}</span>
        </div>
        <!--NON-MEMBER-->
        <div
          v-if="showStandard"
          class="standardTotalCosts"
          :class="{ notApplicable: hasOrWantsMembership === true }"
        >
          <span v-if="showDifferentPricesComputed">{{ $t('shop.price.standard') }}:</span>
          <span>{{ $format.currency(standardSummary.grandTotalAmount, 'CHF') }}</span>
        </div>
        <span
          v-if="hasOrWantsMembership === false && savingsMember > 0"
          class="costsPotentialSavings"
        >
          {{ $t('shop.shoppingBasket.youCouldSave') }}
          <strong>{{ $format.currency(savingsMember, 'CHF') }}</strong>
        </span>
      </div>
    </div>
    <slot>
      <div class="actions">
        <div class="prependActions">
          <p-request-alert v-model="errorResponse"></p-request-alert>
          <slot name="prepend-actions"></slot>
        </div>
        <div class="actionBack">
          <slot name="action-back">
            <p-button class="backButton" @click="goBack()">
              <p-icon :left="!$media.isMobile">
                arrow_back
              </p-icon>
              {{ labelBackComputed }}
            </p-button>
          </slot>
        </div>
        <div class="actionContinue">
          <slot name="action-continue" :disabled="isContinueDisabled">
            <p-button
              class="continueButton"
              :disabled="isContinueDisabled"
              @click="$router.push(to)"
            >
              {{ labelContinueComputed }}
            </p-button>
          </slot>
          <p-row class="continueInfo">
            <span v-if="_.isNil(isMember) && articles.length > 0" class="caption">
              {{ $t('shop.shoppingBasket.membershipPrompt') }}
            </span>
            <span v-if="articles.length < 1" class="caption">
              {{ $t('shop.shoppingBasket.noArticles') }}
            </span>
          </p-row>
        </div>
        <slot name="append-actions"></slot>
      </div>
    </slot>
  </div>
</template>

<script lang="ts">
  import _ from '@glittr/frontend-core/src/utils';
  import Vue from 'vue';
  import BasketModel from '@/src/services/v2/model/basket-model';
  import BasketItemModel from '@/src/services/v2/model/basket-item-model';
  import shoppingBasket from '../../utilities/shoppingBasket';

  export default Vue.extend({
    name: 'IlShopShoppingBasketCosts',
    props: {
      showDifferentPrices: { type: Boolean, default: undefined },
      canChooseMembership: { type: Boolean, default: undefined },
      showVatDetails: { type: Boolean, default: undefined },
      to: { type: String, default: undefined },
      labelContinue: { type: String, default: undefined },
      labelBack: { type: String, default: undefined },
      disabled: { type: Boolean, default: undefined },
    },
    data: () => ({
      errorResponse: undefined,
      isMember: undefined as boolean | null | undefined,
      wantsToBecomeMember: undefined as boolean | undefined,
      basketData: new BasketModel(),
      memberSummary: {} as any,
      standardSummary: {} as any,
      articles: [] as BasketItemModel[],
    }),
    computed: {
      shippingInfo() {
        return this.memberSummary?.shippingInfo ?? {};
      },
      isShippingAmountCalculated(): boolean {
        return this.shippingInfo.isShippingAmountCalculated ?? true;
      },
      isContinueDisabled(): boolean {
        return this.disabled || (this.articles.length < 1 || _.isNil(this.isMember));
      },
      labelBackComputed(): string {
        if (_.isNil(this.labelBack)) {
          return this.$t('core.app.back');
        }
        return this.$t(this.labelBack, this.labelBack);
      },
      labelContinueComputed(): string {
        if (_.isNil(this.labelContinue)) {
          return this.$t('core.app.continue');
        }
        return this.$t(this.labelContinue, this.labelContinue);
      },
      canChooseMembershipComputed(): boolean {
        if (_.isNil(this.canChooseMembership)) {
          return true;
        }
        return this.canChooseMembership;
      },
      showDifferentPricesComputed(): boolean {
        if (_.isNil(this.showDifferentPrices)) {
          return true;
        }
        return this.showDifferentPrices;
      },
      showVatDetailsComputed() : boolean {
        if (_.isNil(this.showVatDetails)) {
          return false;
        }
        return this.showVatDetails;
      },
      showMember(): boolean {
        if (_.isNil(this.showDifferentPrices)) {
          return true;
        }
        return this.showDifferentPrices || !!this.isMember;
      },
      showStandard(): boolean {
        if (_.isNil(this.showDifferentPrices)) {
          return true;
        }
        return this.showDifferentPrices || !this.isMember;
      },
      hasOrWantsMembership(): boolean | undefined {
        if (_.isNil(this.isMember)) {
          return undefined;
        }
        return this.isMember === true || this.wantsToBecomeMember === true;
      },
      savingsMember(): number {
        if (_.isNil(this.standardSummary) || _.isNil(this.memberSummary)) {
          return 0;
        }
        return this.round(
          this.standardSummary.grandTotalAmount
            - this.memberSummary.grandTotalAmount,
          0.05,
        );
      },
    },
    watch: {
      isMember(newValue) {
        shoppingBasket.setIsMember(newValue);
      },
      wantsToBecomeMember(newValue) {
        shoppingBasket.setWantsToBecomeMember(newValue);
      },
    },
    mounted() {
      const isMemberStorage = shoppingBasket.getIsMember();
      this.isMember = isMemberStorage;
      this.populateData();
      this.$eventbus.on('STORAGE/UPDATE/SWISSMECHANIC.ONLINESHOP.SHOPPINGBASKET', this.debouncedPopulateData);
    },
    destroyed() {
      this.$eventbus.off('STORAGE/UPDATE/SWISSMECHANIC.ONLINESHOP.SHOPPINGBASKET', this.debouncedPopulateData);
    },
    methods: {
      goBack() {
        this.$router.back();
        // Due to a bug the "_startLocation" in the router history is not set when loaded as a widget
        // Check to see if the back actually worked and if not just open the main page
        if (!this.$router.history.pending) {
          this.$router.replace('/_widget/shop/article-list');
        }
      },
      debouncedPopulateData() {
        this.$debounce(() => {
          this.populateData();
        }, 400, this)();
      },
      async populateData() {
        this.errorResponse = undefined;
        this.basketData = shoppingBasket.getLocalBasket();
        const articles = this.basketData.basketItems ?? [];
        this.articles = articles;
        try {
          const response = await shoppingBasket.updateBasketFromServer(false);
          this.basketData = response.data;
          this.articles = this.basketData.basketItems ?? [];
          this.basketData.summary = this.basketData.summary ?? [];
          this.memberSummary = this.basketData.summary.find(
            (price) => price.priceType === 'member',
          );
          this.standardSummary = this.basketData.summary.find(
            (price) => price.priceType === 'standard',
          );
          this.memberSummary = this.memberSummary ?? {};
          this.standardSummary = this.standardSummary ?? {};
        } catch (error: any) {
          this.errorResponse = error;
          this.$log.error(error);
        }
      },
      round(value: number, step: number) {
        step = step ?? 1.0;
        const inv = 1.0 / step;
        return Math.round(value * inv) / inv;
      },
    },
  });
</script>
